import { mount, route, lazy, map, redirect } from 'navi';
import React, { useState, Suspense } from 'react';
import { Router, View } from 'react-navi';

import { useKeycloak } from '../lib';
import HomePage from '../pages/Home';
import Main from '../pages/Main';
// import Users from '../pages/Users';
// import LoginPage from '../pages/Login';

import { withAuthentication } from './utils';

// "auth-server-url": "http://sso.mlsp.gov.kg/auth/",


const portalAPI = "https://adoption.mlsp.gov.kg"
// const portalAPI = "http://10.20.6.18"
const keycloakAPI = "https://sso.mlsp.gov.kg" // "http://gbd.mlsp.gov.kg:9190"
// const keycloakAPI = "http://10.20.6.12" // "http://gbd.mlsp.gov.kg:9190"

// Define your routes
const routes = mount({
  '/home': withAuthentication ?
    route({
      title: 'Home',
      view: <HomePage
        portalAPI={portalAPI}
        keycloakAPI={keycloakAPI}
        taskType={"viewHome"}
      />,
    })
    : redirect('/main'),
  '/': redirect('/main'),

  '/main': route({
    title: 'Main',
    view: <Main
      portalAPI={portalAPI}
      keycloakAPI={keycloakAPI}
    />
  }),

  '/login': map(async (request, context) =>
    context.isAuthenticated
      ? redirect(
        request.params.redirectTo ? decodeURIComponent(request.params.redirectTo) : '/home',
      )
      : lazy(() => import('../pages/Login')),
  ),
  '/viewUsers': withAuthentication
    ? route({
      title: 'HomeUsers',
      view: <HomePage
        portalAPI={portalAPI}
        keycloakAPI={keycloakAPI}
        taskType="viewUsers"
      />
    })
    : redirect('/main'),

});
export const AppRouter = () => {
  const [keycloak, initialized] = useKeycloak()

  if (!initialized) {
    return <div>Loading...</div>
  }
  return (
    <Router routes={routes} context={{ isAuthenticated: keycloak.authenticated }}>
      <Suspense fallback={null}>
        <View />
      </Suspense>
    </Router>
  );
};
